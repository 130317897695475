import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { useTranslation } from 'react-i18next'

BottomHelp.propTypes = {}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column wrap',
    textAlign: 'left',
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  about: {
    display: 'flex',
    flexFlow: 'column wrap',
    textAlign: 'left',
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  title: {
    marginBottom: theme.spacing(2),
    ...theme.typography.h7,
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  overline: {
    ...theme.typography.overline,
    color: theme.palette.text.secondary,
  },
}))

function BottomHelp() {
  const classes = useStyles()
  const { t } = useTranslation()
  const columns = [
    {
      title: t('main.footer.sections.help.contactUs'),
      to: '/contact',
    },
    {
      title: t('main.footer.sections.help.userGuide'),
      to: 'user-guides',
    },
    // {
    //   title: 'FAQ',
    //   to: '/faq',
    // },
    {
      title: t('main.footer.sections.help.tutorial'),
      externalLink: true,
      to: 'www.youtube.com/playlist?list=PLlrKvETH-325a1nvTaBQpr0vUwfknOoRr',
    },
    {
      title: t('main.footer.sections.help.releaseNotes'),
      to: 'release-notes',
    },
  ]

  return (
    <nav className={classes.root}>
      <Typography className={classes.title}>
        {t('main.footer.sections.help.title')}
      </Typography>
      {columns.map((column) => {
        return !column?.externalLink ? (
          <Link
            key={column.title}
            component={RouterLink}
            style={{ textDecoration: 'none' }}
            to={column.to ?? '#'}
            className={classes.link}
          >
            <Typography
              component="span"
              display="block"
              className={classes.overline}
            >
              {column.title}
            </Typography>
          </Link>
        ) : (
          <Link
            key={column.title}
            target={'_blank'}
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            href={`//${column.to}`}
            className={classes.link}
          >
            <Typography
              component="span"
              display="block"
              className={classes.overline}
            >
              {column.title}
            </Typography>
          </Link>
        )
      })}
    </nav>
  )
}

export default BottomHelp
