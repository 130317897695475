/* eslint-disable react/prop-types */
import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'
import parseError from 'helpers/errorHelpers'
import {
  usePackage,
  useReadPackage,
  useParseOPF,
} from 'api/Package/Packages/queries'
import { useLocation } from 'react-router'
import { useAttempt } from 'api/Package/Attempts/queries'
import { userRoleSelector } from 'store/auth/selectors'
import { useUserMissions } from 'api/Gamification/UserMissions/queries'
import { useSchoolAssignment } from 'api/Package/SchoolAssignments/queries'
import { useMission } from 'api/Gamification/Missions/queries'
import useNotification from 'hooks/useNotification'
import PackageService from 'api/Package'
import { useSelector } from 'react-redux'
import { generateFilter } from 'helpers/api'
import { groupBy, uniqBy } from 'lodash'
import { useCurrentUser } from 'api/User/Users/queries'
import { useContext } from 'react'
import { useMemo } from 'react'
import { useEpub3State } from '../use-epub3-state.hook'

export const useReaderContextProvider = () => {
  const currentUser = useCurrentUser()

  const IS_AUTHENTICATED = currentUser.isSuccess

  const { addNotification } = useNotification()

  let matchAssignment = useRouteMatch({
    path: '/reader/assignment/:id',
    strict: true,
    sensitive: true,
  })

  let matchMission = useRouteMatch({
    path: '/reader/mission/:id',
    strict: true,
    sensitive: true,
  })
  // --------------
  let matchPreviewMission = useRouteMatch({
    path: '/reader/previewmission/:id',
    strict: true,
    sensitive: true,
  })
  // --------------
  let matchRead = useRouteMatch({
    path: '/reader/:readMode/:packageId',
    strict: true,
    sensitive: true,
  })
  let matchReadWithId = useRouteMatch({
    path: '/reader/:packageId/:readMode/:id',
    strict: true,
    sensitive: true,
  })

  const [pages, setPages] = React.useState([])
  const [currentPage, setCurrentPage] = React.useState()

  const [availableActivities, setAvailableActivities] = React.useState([])
  const [currentActivity, setCurrentActivity] = React.useState()
  const [currentIndex, setCurrentIndex] = React.useState()

  const { readMode } = useParams()

  const IS_ASSIGNMENT = !!matchAssignment?.isExact && readMode === 'assignment'

  const IS_MISSION = !!matchMission?.isExact && readMode === 'mission'
  // --------------
  const IS_PREVIEW_MISSION =
    !!matchRead?.isExact && readMode === 'previewmission'

  // --------------

  const assignment = useSchoolAssignment(matchAssignment?.params?.id, {
    queryFilters: { includeModels: ['Packages'] },
    queryOptions: {
      enabled: !!IS_ASSIGNMENT && IS_AUTHENTICATED,
      staleTime: 60 * 60 * 1000,
      onError: (error) => {
        addNotification({
          type: 'error',
          message: parseError(error, 'Error getting Assignment'),
        })
      },
    },
  })

  const mission = useUserMissions(null, {
    queryFilters: { filters: [{ by: 'id', value: matchMission?.params?.id }] },
    queryOptions: {
      enabled: !!IS_MISSION && IS_AUTHENTICATED,
      staleTime: 60 * 60 * 1000,
      onError: (error) => {
        addNotification({
          type: 'error',
          message: parseError(error, 'Error getting Mission'),
        })
      },
    },
  })

  // ------------------------
  const previewMission = useMission(matchPreviewMission?.params?.id, {
    queryOptions: {
      enabled: !!IS_PREVIEW_MISSION && IS_AUTHENTICATED,
      staleTime: 60 * 60 * 1000,
      onError: (error) => {
        addNotification({
          type: 'error',
          message: parseError(error, 'Error getting Mission'),
        })
      },
    },
  })
  // ------------------------

  const IS_READ =
    !!matchRead?.isExact &&
    readMode === 'read' &&
    !matchReadWithId &&
    IS_AUTHENTICATED

  const IS_READ_WITH_ID = !!matchReadWithId?.isExact && IS_AUTHENTICATED

  const ENABLE_GET_PACKAGE = IS_ASSIGNMENT
    ? assignment.isSuccess
    : IS_MISSION
    ? mission.isSuccess
    : IS_PREVIEW_MISSION
    ? previewMission.isSuccess
    : IS_READ || IS_READ_WITH_ID

  const packageToGet = IS_ASSIGNMENT
    ? assignment.data?.Package.id
    : IS_MISSION
    ? mission.data?.items[0]?.Mission.package_id
    : IS_PREVIEW_MISSION
    ? previewMission.data?.package_id
    : IS_READ || IS_READ_WITH_ID
    ? matchRead?.params?.packageId
    : ''

  const pkg = usePackage(packageToGet, {
    queryOptions: {
      enabled: ENABLE_GET_PACKAGE,
      retry: false,
      onError: (error) => {
        addNotification({
          type: 'error',
          message: parseError(error, `Error finding Package`),
        })
      },
    },
  })

  const IS_HTML5 = pkg.data?.Format.title === 'html5'
  const IS_IWB = pkg.data?.Format.title === 'iwb'
  const IS_DIGIIWB = pkg.data?.Format.title === 'digiiwb'
  const IS_EPUB = pkg.data?.Format.title === 'epub3'
  const IS_DIWB = pkg.data?.Format.title === 'diwb'
  const IS_DIGIPUB = pkg.data?.Format.title === 'digipub'

  const readPackage = useReadPackage(packageToGet, {
    queryOptions: {
      enabled: pkg.isSuccess,
      onError: (error) => {
        addNotification({
          type: 'error',
          message: parseError(error, 'Error reading Package'),
        })
      },
    },
  })

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const attemptId = params.get('attempt')

  let userRole = useSelector(userRoleSelector)

  const CAN_SHOW_ATTEMPTS = [
    'School Master',
    'School Manager',
    'Individual Teacher',
    'Admin',
    'Teacher',
  ]

  const attempts = useAttempt(attemptId, {
    queryOptions: {
      enabled:
        CAN_SHOW_ATTEMPTS.includes(userRole) &&
        !!attemptId &&
        (IS_EPUB || IS_DIWB),
      staleTime: 1000 * 240,
      onError: (error) => {
        addNotification({
          type: 'error',
          message: parseError(error, 'Error getting Attempt'),
        })
      },
    },
  })

  const currentSubSchool = useSelector((state) => state.auth.currentSubSchool)
  const SCHOOL_TYPE_2 = currentSubSchool?.type_id === 2
  const currentSchoolID = SCHOOL_TYPE_2
    ? currentSubSchool?.user_id
    : currentSubSchool?.id

  let dateFilter = mission.data?.items?.[0]?.created
    ? [{ by: 'created[gte]', value: mission.data?.items?.[0]?.created }]
    : []
  const completedUserMissionActivities =
    PackageService.Attempts.hooks.useAttemptsActivitiesNEW(
      {
        subSchoolId: currentSchoolID,
        filters: [
          // { by: 'created[gte]', value: mission.data?.items?.[0]?.created },
          ...dateFilter,
          { by: 'user_id', value: currentUser.data?.id },
          ...generateFilter(
            [],
            mission.data?.items[0]?.Mission.params,
            'activity_id'
          ),
        ],
      },
      {
        enabled: IS_MISSION && mission.isSuccess,
        // enabled: false,
      }
    )

  // const parseOPF = useParseOPF(readPackage.data?.url, {
  const parseOPF = useParseOPF(readPackage.data?.url, pkg.data?.version, {
    queryOptions: {
      enabled:
        readPackage.isSuccess &&
        (IS_EPUB || IS_DIWB) &&
        (IS_MISSION ? completedUserMissionActivities.isSuccess : true),
      onSuccess: (data) => {
        if (IS_PREVIEW_MISSION) {
          let missionActivities = data[1].filter((activity) =>
            previewMission.data?.params.includes(parseInt(activity.activityId))
          )
          setAvailableActivities(missionActivities)
          setCurrentActivity(missionActivities[0])
          setCurrentIndex(0)
        } else if (IS_READ) {
          setAvailableActivities(data[1])
          setPages(data[3])
          setCurrentPage(data[3][0]?.href)
          setCurrentActivity(data[1][0])
          setCurrentIndex(0)
        } else if (IS_READ_WITH_ID) {
          setAvailableActivities(data[1])
          setPages(data[3])
          setCurrentPage(data[3][0]?.href)
          let activityIndex = data[1].findIndex(
            (activity) => activity.id === matchReadWithId?.params?.id
          )
          if (activityIndex !== -1) {
            setCurrentActivity(data[1][activityIndex])
            setCurrentIndex(activityIndex)
          } else {
            setCurrentActivity(data[1][0])
            setCurrentIndex(0)
          }
        } else if (IS_ASSIGNMENT) {
          let assignmentActivity = data[1].find(
            (activity) => activity.activityId == assignment.data?.activity_id
          )
          setAvailableActivities([{ ...assignmentActivity }])
          setCurrentActivity(assignmentActivity)
          setCurrentIndex(0)
        } else if (IS_MISSION) {
          let uniqCompleted = uniqBy(
            completedUserMissionActivities.data?.items ?? [],
            'id'
          )
          let uniqCompletedIDs = uniqCompleted.map((item) => item?.id)
          let missionActivities = data[1].filter(
            (activity) =>
              mission.data?.items?.[0].Mission?.params.includes(
                parseInt(activity.activityId)
              ) && !uniqCompletedIDs.includes(parseInt(activity.activityId))
          )

          setAvailableActivities(missionActivities)
          setCurrentActivity(missionActivities[0])
          setCurrentIndex(0)
        }
      },
      onError: (error) => {
        addNotification({
          type: 'error',
          message: parseError(error, `Error parsing Package`),
        })
      },
    },
  })

  const IS_READING = readPackage.isLoading || parseOPF.isLoading

  const SHOW_CONTENT =
    IS_EPUB || IS_DIWB
      ? pkg.isSuccess && readPackage.isSuccess && parseOPF.isSuccess
      : pkg.isSuccess && readPackage.isSuccess

  let [currentTab, setCurrentTab] = React.useState(0)

  const tabs = [{ text: 'contents' }, { text: 'pages' }]

  const isTabActivities = currentTab === 0
  const isTabPages = currentTab === 1

  const baseURL = useMemo(() => {
    if (!readPackage.data?.url) return ''
    return readPackage.data.url
  }, [readPackage])

  const epub3URL = useMemo(() => {
    if (!currentActivity || (pages.length && !currentPage)) return ``
    return isTabActivities
      ? `${baseURL}OPS/${currentActivity.href}`
      : `${baseURL}OPS/${currentPage}`
  }, [baseURL, currentActivity, currentPage, isTabActivities, pages])

  const html5URL = `${baseURL}index.html`

  const iwbURL = `${baseURL}index.html`

  const digiiwbURL = `${baseURL}index.html`

  const digiURL = `${baseURL}index.html`

  const iRef = React.useRef()

  const studentAnswers = useMemo(() => {
    if (!attemptId) return null
    return attempts?.data?.answers
  }, [attemptId, attempts])

  let groupedActivities = React.useMemo(
    () => groupBy(availableActivities, 'moduleName'),
    [availableActivities]
  )

  const [open, setOpen] = React.useState(false)

  const handleToggleDrawer = () => {
    setOpen((open) => !open)
  }

  // DIGIPUB
  const [toc, setToc] = React.useState({})

  const targetActivities = useMemo(() => {
    return IS_READ_WITH_ID
      ? [matchReadWithId?.params?.id]
      : IS_MISSION
      ? mission.data?.items?.[0].Mission?.params
      : IS_ASSIGNMENT
      ? [assignment.data?.activity_id]
      : IS_PREVIEW_MISSION
      ? previewMission.data?.params ?? []
      : []
  }, [
    IS_ASSIGNMENT,
    IS_MISSION,
    IS_PREVIEW_MISSION,
    IS_READ_WITH_ID,
    assignment,
    matchReadWithId,
    mission,
    previewMission,
  ])

  return {
    showContent: SHOW_CONTENT,
    drawer: {
      open,
      handleToggleDrawer,
    },
    readerMode: {
      IS_HTML5,
      IS_IWB,
      IS_DIGIIWB,
      IS_EPUB,
      IS_DIWB,
      IS_DIGIPUB,
      IS_ASSIGNMENT,
      IS_MISSION,
      IS_PREVIEW_MISSION,
      IS_READ,
      IS_READ_WITH_ID,
    },
    iRef,
    // book: pkg?.data?.version,
    epub3: {
      url: epub3URL,
    },
    html5: {
      url: html5URL,
    },
    iwb: {
      url: iwbURL,
    },
    digiiwb: {
      url: digiiwbURL,
    },
    digipub: {
      url: digiURL,
      toc,
      setToc,
      targetActivities,
    },
    status: {
      IS_READING,
    },
    info: {
      title: pkg.data?.title ?? '',
      baseURL,
      studentAnswers,
      currentActivity,
      currentIndex,
      availableActivities,
      pages,
      currentPage,
      currentTab,
      isTabActivities,
      isTabPages,
      groupedActivities,
      tabs,
    },
    actions: {
      setCurrentTab,
      setCurrentPage,
      setCurrentActivity,
      setCurrentIndex,
      setAvailableActivities,
    },
  }
}

const ReaderContext = React.createContext()

export const ReaderContextProvider = ({ children }) => {
  const value = useReaderContextProvider()

  return (
    <ReaderContext.Provider value={value}>{children}</ReaderContext.Provider>
  )
}

export const useReaderContext = () => useContext(ReaderContext)
