const LOGIN = 'LOGIN'
const LOGOUT = 'LOGOUT'
const RESET_APP = 'RESET_APP'
const SET_USER = 'SET_USER'
const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
const SET_CURRENT_SCHOOL_YEAR = 'SET_CURRENT_SCHOOL_YEAR'
const SET_CURRENT_SUB_SCHOOL = 'SET_CURRENT_SUB_SCHOOL'
const SET_INDIVIDUAL_TEACHER = 'SET_INDIVIDUAL_TEACHER'
const SET_DIGIPLUS_PENDING = 'SET_DIGIPLUS_PENDING'

export {
  LOGIN,
  LOGOUT,
  SET_USER,
  SET_ACCESS_TOKEN,
  SET_CURRENT_SCHOOL_YEAR,
  SET_CURRENT_SUB_SCHOOL,
  RESET_APP,
  SET_INDIVIDUAL_TEACHER,
  SET_DIGIPLUS_PENDING,
}
