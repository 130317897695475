import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'
import { CircularProgress, Grid } from '@material-ui/core'
import parseError from 'helpers/errorHelpers'
import {
  usePackage,
  useReadPackage,
  useParseOPF,
} from 'api/Package/Packages/queries'
import { useLocation } from 'react-router'
import { useAttempt } from 'api/Package/Attempts/queries'
import { userRoleSelector } from 'store/auth/selectors'
import { useUserMissions } from 'api/Gamification/UserMissions/queries'
import { useSchoolAssignment } from 'api/Package/SchoolAssignments/queries'
import { useMission } from 'api/Gamification/Missions/queries'
import useNotification from 'hooks/useNotification'

import BaseBackdrop from 'ui/BaseBackdrop'
import ReaderEPUB3, { Epub3Reader } from './epub3-reader.component'
import ReaderHTML5 from './html5-reader.component'
import ReaderIWB from './iwb-reader.component'
import ReaderDigiIWB from './digiiwb-reader.component'
import { ReaderDigipub } from './digipub-reader.component'
import PackageService from 'api/Package'
import { useSelector } from 'react-redux'
import { generateFilter } from 'helpers/api'
import { uniqBy } from 'lodash'
import { useCurrentUser } from 'api/User/Users/queries'
import { useReaderContext } from './context/reader.context'
import { ReaderLoader } from './reader-loader.component'

export const ReaderContent = () => {
  const {
    showContent,
    readerMode: { IS_EPUB, IS_HTML5, IS_DIGIPUB, IS_DIWB, IS_IWB, IS_DIGIIWB },
  } = useReaderContext()
  return (
    <div>
      <ReaderLoader />
      {showContent ? (
        <>
          {IS_EPUB || IS_DIWB ? (
            <Epub3Reader />
          ) : IS_HTML5 ? (
            <ReaderHTML5
            // title={pkg.data?.title} url={readPackage.data?.url}
            />
          ) : IS_DIGIPUB ? (
            <ReaderDigipub />
          ) : IS_IWB ? (
            <ReaderIWB />
          ) : IS_DIGIIWB ? (
            <ReaderDigiIWB />
          ) : null}
        </>
      ) : null}
    </div>
  )
}
