/* eslint-disable react/prop-types */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, withStyles, alpha } from '@material-ui/core/styles'
import {
  Typography,
  IconButton,
  Grid,
  List,
  Divider,
  ListItemText,
  ListItem,
  ListSubheader,
  Collapse,
  Tooltip,
  Box,
} from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'
import { useReaderContext } from './context/reader.context'

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 320,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: theme.typography.pxToRem(10),
    padding: theme.spacing(1, 2),
  },
}))(Tooltip)

const StyledDivider = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    height: 2,
  },
}))(Divider)

const StyledListSubheader = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    '& .MuiTypography-root': {
      fontSize: 15,
      fontWeight: 400,
      padding: theme.spacing(0.5, 0, 0.5),
    },
  },
}))(ListSubheader)

const StyledListItem = withStyles((theme) => ({
  root: {
    '& .MuiListItemText-root .MuiTypography-root': {
      fontSize: 14,
      fontWeight: 500,
    },
    '&$selected': {
      backgroundColor: alpha(theme.palette.primary.main, 0.7),
      '&  .MuiListItemText-root .MuiTypography-root': {
        color: 'white',
      },
    },
    '&$selected:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.7),
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
  },
  selected: {},
}))((props) => <ListItem {...props} component="div" />)

const useStyles = makeStyles(() => ({
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8,
  },
  wrapper: {
    width: 300,
    height: 220,
    // overflow: 'hidden',
  },
  iframe: {
    borderBottom: '1px solid black',
    pointerEvents: 'none',
    width: 1080,
    height: 800,
    transform: 'scale(0.3)',
    transformOrigin: '0 0 ',
  },
}))

function DrawerContent({ onExerciseClick }) {
  const classes = useStyles()

  const {
    info: {
      baseURL,
      currentActivity,
      isTabActivities,
      isTabPages,
      groupedActivities,

      pages,
    },
    readerMode: { IS_EPUB, IS_DIGIPUB, IS_DIWB },
    digipub: { toc },
    actions: { setCurrentPage },
  } = useReaderContext()

  const [mapState, setMapState] = React.useState(new Map())
  const updateMap = (key, value) => {
    setMapState((prev) => new Map([...prev, [key, value]]))
  }

  let content = useMemo(() => {
    if (IS_DIGIPUB) {
      return toc
    }
    if (IS_EPUB || IS_DIWB) {
      return groupedActivities
    }
    return {}
  }, [IS_DIGIPUB, IS_EPUB, IS_DIWB, groupedActivities, toc])

  let [data, setData] = React.useState(Object.entries(content))

  const initExpandedStates = () => {
    let modulesMap = {}
    for (let moduleName in content) {
      if (IS_DIGIPUB) {
        updateMap(moduleName, content[moduleName][0].moduleid)
        modulesMap[content[moduleName][0].moduleid] = true
      } else {
        updateMap(moduleName, content[moduleName][0].moduleId)
        modulesMap[content[moduleName][0].moduleId] = true
      }
    }
    return modulesMap
  }
  React.useEffect(() => {
    setExpandedModuleState(initExpandedStates())
    setData(Object.entries(content))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content])

  let [expandedModuleState, setExpandedModuleState] =
    React.useState(initExpandedStates)

  const handleExpandModule = (moduleName) => {
    let moduleId = mapState.get(moduleName)
    setExpandedModuleState((prev) => ({ ...prev, [moduleId]: !prev[moduleId] }))
  }

  const handleClickPage = (href) => {
    setCurrentPage(href)
  }
  return (
    <>
      {data.map((item, index) => (
        <List
          key={index}
          component="nav"
          subheader={
            <Grid
              container
              wrap="nowrap"
              justifyContent="space-between"
              alignContent="center"
            >
              <Grid item>
                <StyledListSubheader component="div">
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    {item[0]}
                  </Typography>
                </StyledListSubheader>
              </Grid>
              <Grid item className={classes.iconWrapper}>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => handleExpandModule(item[0])}
                >
                  {expandedModuleState[mapState.get(item[0])] ? (
                    <ArrowDropUp />
                  ) : (
                    <ArrowDropDown />
                  )}
                </IconButton>
              </Grid>
            </Grid>
          }
          style={{
            display: isTabActivities ? 'block' : 'none',
          }}
        >
          <StyledDivider />
          <Collapse
            in={expandedModuleState[mapState.get(item[0])]}
            timeout="auto"
            unmountOnExit
          >
            {item[1].map((activity) => (
              <StyledListItem
                button
                key={IS_DIGIPUB ? activity?.digi_activity_id : activity?.id}
                onClick={() => onExerciseClick(activity)}
                selected={
                  IS_DIGIPUB
                    ? currentActivity?.digi_activity_id ===
                      activity?.digi_activity_id
                    : currentActivity?.id === activity?.id
                }
              >
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" noWrap={false}>
                      {IS_DIGIPUB
                        ? activity?.digi_activity_name
                        : activity?.activityName || activity.href}
                    </Typography>
                  }
                />
              </StyledListItem>
            ))}
          </Collapse>
        </List>
      ))}
      {
        <Box
          style={{
            display: isTabPages ? 'block' : 'none',
          }}
        >
          {pages.map((page, index) => (
            <LightTooltip
              key={index}
              placement="right"
              arrow
              // title empty for , till right info is available from back
              title={''}
            >
              <div
                className={classes.wrapper}
                onClick={() => handleClickPage(page.href)}
                style={{
                  cursor: 'pointer',
                  paddingBottom: '10px',
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
              >
                <iframe
                  key={index}
                  id="iframe"
                  name="iframe"
                  loading="lazy"
                  frameBorder="0"
                  src={`${baseURL}OPS/${page.href}`}
                  className={classes.iframe}
                  onLoad={(e) => {
                    e.stopPropagation()
                  }}
                />
              </div>
            </LightTooltip>
          ))}
        </Box>
      }
    </>
  )
}

export default React.memo(DrawerContent)
