import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { LinearProgress, Container } from '@material-ui/core'

import ReaderFrame from './iwb-reader-frame.component'
import { useReaderContext } from './context/reader.context'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
}))

function ReaderIWB() {
  const classes = useStyles()

  const {
    iRef,
    iwb: { url },
  } = useReaderContext()

  const [hasLoaded, setHasLoaded] = React.useState(false)

  const handleLoad = () => {
    setHasLoaded(true)
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="xl" disableGutters className={classes.main}>
        {!hasLoaded ? <LinearProgress style={{ width: '100%' }} /> : null}
        <ReaderFrame
          ref={iRef}
          variant={'iwb'}
          loaded={hasLoaded}
          frameSrc={url}
          onLoad={handleLoad}
        />
      </Container>
    </div>
  )
}

ReaderIWB.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  availableActivities: PropTypes.array,
  currentActivity: PropTypes.any,
  currentIndex: PropTypes.any,
  setActivity: PropTypes.func,
  setIndex: PropTypes.func,
}

export default ReaderIWB
