import React, { useCallback, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'

import { useCreateAttempt } from 'api/Package/Attempts/queries'
import useNotification from 'hooks/useNotification'
import parseError from 'helpers/errorHelpers'
import useWindowSize from 'hooks/useWindowSize'
import { userRoleSelector } from 'store/auth/selectors'
import { useSubSchoolSetting } from 'api/School/SubSchoolSettings/queries'
import IndTeacherService from 'api/IndividualTeacher'
import { useSelector } from 'react-redux'
import { useReaderContext } from './context/reader.context'
import { useMemo } from 'react'

const drawerWidth = 300

function bindEvent(element, eventName, eventHandler) {
  if (element.addEventListener) {
    element.addEventListener(eventName, eventHandler, false)
  } else if (element.attachEvent) {
    element.attachEvent('on' + eventName, eventHandler)
  }
}
function unBindEvent(element, eventName, eventHandler) {
  if (element.removeEventListener) {
    element.removeEventListener(eventName, eventHandler)
  } else if (element.detachEvent) {
    element.detachEvent('on' + eventName, eventHandler)
  }
}

export const useEpub3State = () => {
  const {
    iRef,
    info: {
      currentActivity,
      availableActivities,
      pages,
      currentPage,
      studentAnswers,
      currentIndex,
      isTabPages,
    },
    drawer: { open },
    actions: { setCurrentActivity, setCurrentIndex, setCurrentPage },
  } = useReaderContext()
  const theme = useTheme()
  const isScore100Ref = React.useRef(false)

  let userRole = useSelector(userRoleSelector)

  const currentSubSchool = useSelector((state) => state.auth.currentSubSchool)
  const SCHOOL_TYPE_2 = currentSubSchool?.type_id === 2

  // roles that don't post attempts
  const ROLES_WITHOUT_ATTEMPTS = [
    'School Master',
    'School Manager',
    'Individual Teacher',
    'Admin',
    'Teacher',
    'Editor',
  ]

  // roles than can see answers
  const CAN_SHOW_ANSWERS_ROLES = useMemo(
    () => [
      'School Master',
      'School Manager',
      'Individual Teacher',
      'Admin',
      'Teacher',
      'Member',
      'Editor',
    ],
    []
  )

  const upLg = useMediaQuery(theme.breakpoints.up('xl'))

  const { width: windowWidth } = useWindowSize()

  const [, setRetries] = React.useState(0)

  let allowStudentsSeeShowAnswers = useSubSchoolSetting(11, {
    enabled: !SCHOOL_TYPE_2 && 'Member' !== userRole,
  })

  let showAnswersIndTeacherSetting =
    IndTeacherService.Settings.hooks.useIndTeacherSetting(11, {
      enabled: SCHOOL_TYPE_2,
    })

  const SHOW_ANSWERS_BUTTON = useMemo(() => {
    return CAN_SHOW_ANSWERS_ROLES.includes(userRole) ||
      (!SCHOOL_TYPE_2 && allowStudentsSeeShowAnswers.data?.active)
      ? true
      : SCHOOL_TYPE_2
      ? showAnswersIndTeacherSetting.data?.active
      : false
  }, [
    CAN_SHOW_ANSWERS_ROLES,
    userRole,
    SCHOOL_TYPE_2,
    allowStudentsSeeShowAnswers.data,
    showAnswersIndTeacherSetting.data,
  ])

  const currentActivityRef = React.useRef()

  useEffect(() => {
    currentActivityRef.current = currentActivity
  }, [currentActivity])
  const canPostAttemptRef = React.useRef(true)

  const [hasLoaded, setHasLoaded] = React.useState(false)

  const { addNotification } = useNotification()

  const createAttempt = useCreateAttempt({
    queryOptions: {
      onSuccess: async (data) => {
        if (data.elecs) {
          addNotification({
            type: 'success',
            message: `You have earned ${data.elecs} ELECs`,
          })
        }
      },
      onError: (error) => {
        addNotification({
          type: 'error',
          message: parseError(error, `Error creating Attempt`),
        })
      },
    },
  })

  let calculatedMargin = React.useMemo(() => {
    if ((upLg ? 1920 : windowWidth) - (open ? drawerWidth : 0) > 1060) {
      return `${
        ((upLg ? 1920 : windowWidth) - (open ? drawerWidth : 0) - 1060) / 2
      }px`
    } else return '0px'
  }, [upLg, windowWidth, open])

  React.useEffect(() => {
    bindEvent(window, 'message', handleMessage)
    return () => unBindEvent(window, 'message', handleMessage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCheckScore = (score, answers) => {
    if (!currentActivity) return

    if (isScore100Ref.current) {
      console.log('Score is 100, no further attempts allowed.')
      return
    }

    if (score == 100) {
      isScore100Ref.current = true
    }

    createAttempt.mutate({
      score: score,
      answers: answers,
      activity_id: currentActivityRef.current?.activityId,
    })
  }

  const handleMessage = (obj) => {
    // update: fixed
    // console.log('handleMessage', currentActivity) // auto ftaei  TODO fix
    let message
    let answers
    let score
    if (typeof obj.data === 'string' && obj.data !== 'recaptcha-setup') {
      message = JSON.parse(obj.data)

      if (message && message.action === 'checkscore') {
        if (ROLES_WITHOUT_ATTEMPTS.includes(userRole)) return
        if (canPostAttemptRef.current) {
          answers = message.answers
          score = message.value
          handleCheckScore(score, answers)

          canPostAttemptRef.current = false
        }
      } else if (message && message.action === 'tryagain') {
        canPostAttemptRef.current = true
        setRetries((prev) => prev + 1)
      } else if (message && message.action === 'reset') {
        canPostAttemptRef.current = true
        setRetries((prev) => prev + 1)
        isScore100Ref.current = false
      }

      if (message && message.action === 'pageload') {
        let [page] = message?.value?.split('OPS/')?.[1]?.split('#')
        if (page && page !== currentPage) {
          let matchedActivityIdx = availableActivities.findIndex(
            (activity) => activity.href === page
          )
          if (matchedActivityIdx !== -1) {
            setCurrentActivity(availableActivities[matchedActivityIdx])
            setCurrentIndex(matchedActivityIdx)
          }
          if (isTabPages) {
            const matchedPage = pages.find((p) => p === page)
            matchedPage && setCurrentPage(matchedPage)
          }
        }
        // setHasLoaded(true) // axrhsto ginetai explicitely apo to idio to iframe
      }
    }
  }
  const showStudentAnswers = useCallback(() => {
    const payload = {
      action: 'Student Answers',
      answers: studentAnswers,
    }
    let message = JSON.stringify(payload)
    if (!iRef?.current) return
    iRef?.current.contentWindow.postMessage(message, '*')
  }, [iRef, studentAnswers])

  const showAnswers = useCallback(() => {
    if (!iRef?.current) return
    iRef.current.contentWindow.postMessage('Show Answers', '*')
  }, [iRef])

  const showNextAnswer = useCallback(() => {
    if (!iRef?.current) return
    iRef.current.contentWindow.postMessage('Show Next Answer', '*')
  }, [iRef])

  const hideLastAnswer = useCallback(() => {
    if (!iRef?.current) return
    iRef.current.contentWindow.postMessage('Hide Last Answer', '*')
  }, [iRef])

  const resetVariables = useCallback(() => {
    setHasLoaded(false)
    setRetries(0)
    canPostAttemptRef.current = true
  }, [setHasLoaded, setRetries])

  const handleClickExercise = useCallback(
    (activity) => {
      if (!currentActivity) return
      if (activity.activityId === currentActivity.activityId) return
      setCurrentActivity(activity)

      let index = availableActivities.findIndex(
        (avail) => avail.activityId === activity.activityId
      )
      setCurrentIndex(index)
      resetVariables()
    },
    [
      availableActivities,
      currentActivity,
      resetVariables,
      setCurrentActivity,
      setCurrentIndex,
    ]
  )

  const handleNext = useCallback(() => {
    if (currentIndex < availableActivities?.length) {
      if (availableActivities[currentIndex + 1]) {
        setCurrentActivity(availableActivities[currentIndex + 1])
      }
      // currentActivityRef.current = availableActivities[currentIndex + 1]
      setCurrentIndex(currentIndex + 1)
    }
    resetVariables()
  }, [
    availableActivities,
    currentIndex,
    resetVariables,
    setCurrentActivity,
    setCurrentIndex,
  ])

  const handlePrev = useCallback(() => {
    if (currentIndex !== 0) {
      if (availableActivities[currentIndex - 1]) {
        setCurrentActivity(availableActivities[currentIndex - 1])
      }
      // currentActivityRef.current = availableActivities[currentIndex - 1]
      setCurrentIndex(currentIndex - 1)
    }
    resetVariables()
  }, [
    availableActivities,
    currentIndex,
    resetVariables,
    setCurrentActivity,
    setCurrentIndex,
  ])

  const handleLoad = useCallback(() => {
    if (studentAnswers) {
      showStudentAnswers()
    }
    setHasLoaded(true)
  }, [showStudentAnswers, studentAnswers])

  const hasNext = React.useMemo(
    () =>
      availableActivities?.length - 1 !== currentIndex &&
      availableActivities?.length > 0,
    [availableActivities?.length, currentIndex]
  )

  const hasPrev = React.useMemo(() => currentIndex !== 0, [currentIndex])

  return {
    bindEvent,
    unBindEvent,
    iRef,
    ui: {
      upLg,
      SHOW_ANSWERS_BUTTON,
      open,
      hasNext,
      hasPrev,
    },
    actions: {
      handleLoad,
      handleNext,
      handlePrev,
      handleClickExercise,
      showAnswers,
      showNextAnswer,
      hideLastAnswer,
      showStudentAnswers,
    },
    calculatedMargin,
    hasLoaded,
  }
}
