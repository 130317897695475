/* eslint-disable no-undef */
import React, { Suspense, lazy } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { useMutation } from 'react-query'
import { makeStyles } from '@material-ui/core/styles'
import { LinearProgress, Container } from '@material-ui/core'
import * as Sentry from '@sentry/react'
import PrivacyPopup from 'components/PrivacyPopup/PrivacyPopup'
import { AuthenticatedRoute, GuestRoute } from 'router'
import { ForgotPasswordContextProvider } from 'context/ForgotPassword'

import { setUser, setAccessToken } from 'store/auth/actions'
import Notification from 'ui/Notification'
import Landing from 'pages/Landing'
import PasswordChange from 'pages/PasswordChange'

const SignUp = lazy(() => import('pages/SignUp'))
const Contact = lazy(() => import('pages/Contact'))
const UserGuides = lazy(() => import('pages/UserGuides'))
const Dashboard = lazy(() => import('pages/Dashboard'))
const Download = lazy(() => import('pages/Download'))
const DownloadEbooks = lazy(() => import('pages/DownloadEbooks'))
const Forgot = lazy(() => import('pages/ForgotPassword'))
const ParentConsent = lazy(() => import('pages/ParentConsent'))
const EmailVerification = lazy(() => import('pages/EmailVerification'))
// static content
const Terms = lazy(() => import('pages/Terms'))
const ReleaseNotes = lazy(() => import('pages/ReleaseNotes'))
const AboutUs = lazy(() => import('pages/AboutUs'))
const Privacy = lazy(() => import('pages/Privacy'))
const CookiePolicy = lazy(() => import('pages/CookiePolicy'))
import { useDispatch, useSelector } from 'react-redux'
import Reader from 'pages/Reader'
import { refreshToken, getUser } from 'api/User/Users/queries'
import GeneralBoundary from 'ui/Boundary/GeneralBoundary'
import store from 'store'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
  },
}))

const SentryRoute = Sentry.withSentryRouting(Route)

const reload = () => window.location.reload()

function App() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const userId = useSelector((state) => state.auth.user?.id)
  const [isChecking, setIsChecking] = React.useState(false)

  const location = useLocation()

  const userMutation = useMutation(getUser)

  const initUser = async () => {
    const tokenExists = store.getState().auth.accessToken
    if (!tokenExists) {
      return
    }
    setIsChecking(true)
    try {
      const {
        auth: { access_token },
        user,
      } = await refreshToken()
      dispatch(setAccessToken(access_token))
      let { user: foundUser } = await userMutation.mutateAsync({
        queryKey: [null, user?.id],
      })
      dispatch(setUser(foundUser))
      if (userId !== user?.id) history.push('/dashboard')
    } catch (err) {
      // do nothing
    } finally {
      setIsChecking(false)
    }
  }

  React.useEffect(() => {
    initUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <GeneralBoundary key={location.pathname}>
      {isChecking ? (
        <LinearProgress />
      ) : (
        <Container maxWidth="xl" disableGutters className={classes.root}>
          <Suspense fallback={<LinearProgress />}>
            <Switch>
              <SentryRoute
                exact
                path="/.well-known/apple-app-site-association"
                onEnter={reload}
              />
              <SentryRoute exact path="/">
                <Landing />
              </SentryRoute>
              <SentryRoute exact path="/release-notes">
                <ReleaseNotes />
              </SentryRoute>
              <GuestRoute path="/login">
                <Landing />
              </GuestRoute>
              <GuestRoute path="/signup">
                <SignUp />
              </GuestRoute>
              <GuestRoute path="/forgot">
                <ForgotPasswordContextProvider>
                  <Forgot />
                </ForgotPasswordContextProvider>
              </GuestRoute>
              <GuestRoute path="/consent">
                <ParentConsent />
              </GuestRoute>
              <SentryRoute path="/verify">
                <EmailVerification />
              </SentryRoute>
              <SentryRoute path="/contact">
                <Contact />
              </SentryRoute>
              <SentryRoute path="/user-guides">
                <UserGuides />
              </SentryRoute>
              <SentryRoute path="/download">
                <Download />
              </SentryRoute>
              <SentryRoute path="/epiebooks">
                <DownloadEbooks />
              </SentryRoute>
              <AuthenticatedRoute path="/dashboard">
                <Dashboard />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/password-change">
                <PasswordChange />
              </AuthenticatedRoute>
              {/* ------------------------------------------ STATIC CONTENT ------------------------------------------ */}
              <SentryRoute exact path="/privacy">
                <Privacy />
              </SentryRoute>
              <SentryRoute exact path="/terms">
                <Terms />
              </SentryRoute>
              <SentryRoute exact path="/cookie-policy">
                <CookiePolicy />
              </SentryRoute>
              <SentryRoute exact path="/about-us">
                <AboutUs />
              </SentryRoute>
              {/* ------------------------------------------ READER ------------------------------------------ */}
              <Redirect
                exact
                from={`/reader/:packageId`}
                to={`/reader/read/:packageId`}
              />
              <AuthenticatedRoute exact path={`/reader/:readMode/:packageId`}>
                <Reader />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={`/reader/:readMode/t/:packageId`}>
                <Reader />
              </AuthenticatedRoute>
              <AuthenticatedRoute path={`/reader/:readMode/:packageId/:id`}>
                <Reader />
              </AuthenticatedRoute>

              {/* <AuthenticatedRoute exact path={`/reader/assignment/:id`}>
                      <Reader />
                    </AuthenticatedRoute> */}
              <Redirect to="/" />
            </Switch>
            <Notification />
            <PrivacyPopup />
          </Suspense>
        </Container>
      )}
    </GeneralBoundary>
  )
}

export default App
