import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Grow } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  frameWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  digiiwb: {
    padding: 0,
    width: '100%',
    height: '100%',
  },
  iframe: {
    width: '100%',
    height: '100%',
  },
}))

const ReaderFrame = React.forwardRef(
  (
    {
      variant = 'digiiwb',
      frameSrc = '',
      loaded = false,
      frameMargin = '0px',
      onLoad = () => {},
    },
    ref
  ) => {
    const classes = useStyles()

    return (
      <>
        {frameSrc ? (
          <div
            className={clsx(classes.frameWrapper, {
              [classes.digiiwb]: variant === 'digiiwb',
            })}
          >
            <Grow in={loaded}>
              <iframe
                ref={ref}
                id="iframe"
                name="iframe"
                loading="lazy"
                frameBorder="0"
                onLoad={onLoad}
                src={frameSrc}
                style={{
                  marginLeft: frameMargin,
                }}
                allowFullScreen
                className={clsx(classes.iframe)}
              />
            </Grow>
          </div>
        ) : null}
      </>
    )
  }
)

ReaderFrame.displayName = ReaderFrame

ReaderFrame.propTypes = {
  loaded: PropTypes.bool,
  frameSrc: PropTypes.string,
  frameMargin: PropTypes.string,
  onLoad: PropTypes.func,
  variant: PropTypes.string,
}

export default ReaderFrame
