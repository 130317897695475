import { useMutation, useQuery } from 'react-query'
import xml2js from 'xml2js'

import auth from 'api'
import { getCrossplatformPackages } from 'api/Package/CrossPlatformPackages/queries'
import {
  generateFilter,
  parseFilters,
  parsePage,
  parseSearch,
} from 'helpers/api'

import store from 'store'
import {
  setActivities,
  setActivity,
  setPackage,
  setReadType,
  setSpine,
  setToc,
  setURL,
} from 'store/reader/actions'
const { dispatch } = store

// -----------------------------------
export const searchTablePackages = async ({ queryKey }) => {
  const [, page, { searchModels = {} } = {}] = queryKey

  let packagesSearch
  if (searchModels['Packages']) {
    packagesSearch = parseSearch('', searchModels, 'Packages')
  }

  const foundPackages = await getPackages({
    queryKey: [
      null,
      page,
      {
        search: packagesSearch,
      },
    ],
  })

  return foundPackages
}
export const useSearchTablePackages = (
  page,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  const paginationIsDisabled = page == null

  const { data, ...rest } = useQuery(
    [
      'searchPackages',
      paginationIsDisabled ? null : page + 1,
      { ...queryFilters },
    ],
    searchTablePackages,
    {
      refetchOnWindowFocus: false,
      // keepPreviousData: paginationIsDisabled ? false : true,
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 10,
      ...queryOptions,
    }
  )

  // React.useEffect(() => {
  //   if (data?.total_pages > data?.current_page && !paginationIsDisabled) {
  //     queryClient.prefetchQuery(
  //       ['searchPackages', page + 2, { ...queryFilters }],
  //       searchTablePackages
  //     )
  //   }
  // }, [data, page, queryClient, queryFilters, paginationIsDisabled])

  return { data, ...rest }
}

// -----------------------------------

export const searchTableUserPackages = async ({ queryKey }) => {
  const [, page, { filters = [], includeModels = [], searchModels = {} } = {}] =
    queryKey

  let foundPackages
  let packagesIds = []

  let userPackagesFilters = filters

  if (searchModels['Packages']) {
    let packagesSearch = parseSearch('', searchModels, 'Packages')
    foundPackages = await getPackages({
      queryKey: [
        null,
        null,
        {
          search: packagesSearch,
        },
      ],
    })
    packagesIds = foundPackages?.items.map((pkg) => pkg.id)

    userPackagesFilters = generateFilter(filters, packagesIds, 'package_id')
  }

  let includeModelsUserPackages = []
  if (!searchModels['Packages']) includeModelsUserPackages.push('Packages')

  if (searchModels['Packages'] && foundPackages.total_items === 0)
    return { items: [] }

  let tagSearch = ''
  if (searchModels['UserPackages']) {
    tagSearch = parseSearch('', searchModels, 'UserPackages')
  }

  const foundUserPackages = await getUserPackages({
    queryKey: [
      null,
      page,
      {
        search: tagSearch,
        filters: userPackagesFilters,
        includeModels: includeModelsUserPackages,
      },
    ],
  })

  let userPackagesWithData = foundUserPackages
  if (includeModels?.includes('Packages') && foundPackages?.items?.length) {
    userPackagesWithData.items = foundUserPackages.items.reduce(
      (acc, userpkg) => {
        let current
        let idx = foundPackages.items.findIndex(
          (pck) => pck.id === userpkg.package_id
        )
        if (idx !== -1)
          return [
            ...acc,
            { ...userpkg, Packages: { ...foundPackages.items[idx] } },
          ]
        else return [...acc, current]
      },
      []
    )
  }

  return userPackagesWithData
}
export const useSearchTableUserPackages = (
  page,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  const paginationIsDisabled = page == null

  const { data, ...rest } = useQuery(
    [
      'searchUserPackages',
      paginationIsDisabled ? null : page + 1,
      { ...queryFilters },
    ],
    searchTableUserPackages,
    {
      refetchOnWindowFocus: false,
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 10,
      ...queryOptions,
    }
  )

  return { data, ...rest }
}

// -----------------------------------

export const getUserPackages = async ({ queryKey }) => {
  const [, page, { filters, search } = {}] = queryKey
  let condition = ''
  condition = parsePage(page, condition)
  condition = parseFilters(filters, condition)
  if (search) condition += `&${search}`

  const { data } = await auth({
    method: 'get',
    url: `auth/pkg/user/packages${condition}`,
  })
  return data
}
export const useUserPackages = (
  page,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  const paginationIsDisabled = page == null

  const { data, ...rest } = useQuery(
    [
      'userPackages',
      paginationIsDisabled ? null : page + 1,
      { ...queryFilters },
    ],
    getUserPackages,
    {
      keepPreviousData: paginationIsDisabled ? false : true,
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 1,
      ...queryOptions,
    }
  )

  return { data, ...rest }
}
// -----------------------------------

export const getUserPackagesLibrary = async () => {
  let page = 1
  let filters = [{ by: 'per_page', value: 500 }]
  let condition = ''
  condition = parsePage(page, condition)
  condition = parseFilters(filters, condition)

  const { data } = await auth({
    method: 'get',
    url: `auth/pkg/user/packages${condition}`,
  })

  let userPackages = data
  if (data?.total_pages > 1) {
    for (let i = 2; i <= data.total_pages; i++) {
      condition = ''
      condition = parsePage(i, condition)
      condition = parseFilters(filters, condition)

      const { data: data2 } = await auth({
        method: 'get',
        url: `auth/pkg/user/packages${condition}`,
      })
      userPackages.items = [...userPackages.items, ...data2.items]
    }
  }

  return userPackages
}
export const useUserPackagesLibrary = ({
  queryFilters = {},
  queryOptions = {},
} = {}) => {
  const { data, ...rest } = useQuery(
    ['userPackages', { ...queryFilters }],
    getUserPackagesLibrary,
    {
      ...queryOptions,
    }
  )

  return { data, ...rest }
}
// -----------------------------------
export const getPackages = async ({ queryKey }) => {
  const [, page, { filters, search } = {}] = queryKey
  let condition = ''
  condition = parsePage(page, condition)
  condition = parseFilters(filters, condition)
  if (search) condition += `&${search}`
  const { data } = await auth({
    method: 'get',
    url: `auth/pkg/packages${condition}`,
  })
  return data
}
export const usePackages = (
  page,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  const paginationIsDisabled = page == null

  const { data, ...rest } = useQuery(
    ['packages', paginationIsDisabled ? null : page + 1, { ...queryFilters }],
    getPackages,
    {
      keepPreviousData: paginationIsDisabled ? false : true,
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 10,
      ...queryOptions,
    }
  )

  return { data, ...rest }
}
// -----------------------------------
export const getPackage = async ({ queryKey }) => {
  const [, id] = queryKey
  const { data } = await auth({
    method: 'get',
    url: `auth/pkg/packages/${id}`,
  })
  return data
}
export const usePackage = (id, { queryOptions } = {}) => {
  return useQuery(['package', id], getPackage, {
    cacheTime: 1000 * 60 * 60,
    staleTime: 1000 * 60 * 60,
    ...queryOptions,
  })
}

// -----------------------------------
export const getPackageWithCrossplatformData = async ({ queryKey }) => {
  const [, id] = queryKey

  const { data } = await auth({
    method: 'get',
    url: `auth/pkg/packages/${id}`,
  })

  let packageWithData = data

  const crossplatformPackage = await getCrossplatformPackages({
    queryKey: [null, null, { packages: [packageWithData.id] }],
  })
  packageWithData.CrossplatformPackage = { ...crossplatformPackage.items[0] }

  return packageWithData
}
export const usePackageWithCrossplatformData = (
  id,
  { queryOptions = {} } = {}
) => {
  return useQuery(
    ['packageWithCrossplatformData', id],
    getPackageWithCrossplatformData,
    {
      ...queryOptions,
    }
  )
}
// -----------------------------------
export const searchPackages = async ({ filters }) => {
  let condition = ''
  condition = parseFilters(filters, condition)
  const { data } = await auth({
    method: 'get',
    url: `auth/pkg/packages?per_page=0${condition}`,
  })
  return data
}
export const useSearchPackages = ({ queryOptions } = {}) => {
  return useMutation(searchPackages, {
    ...queryOptions,
  })
}
// -----------------------------------
export const createPackage = async (payload) => {
  const { data } = await auth({
    method: 'post',
    url: `auth/pkg/packages`,
    data: {
      ...payload,
    },
  })
  return data
}
export const useCreatePackage = ({ queryOptions } = {}) => {
  return useMutation(createPackage, {
    ...queryOptions,
  })
}
// -----------------------------------
export const createUserPackage = async (payload) => {
  const { data } = await auth({
    method: 'post',
    url: `auth/pkg/user/packages`,
    data: {
      ...payload,
    },
  })
  return data
}
export const useCreateUserPackage = ({ queryOptions } = {}) => {
  return useMutation(createUserPackage, {
    ...queryOptions,
  })
}
// -----------------------------------
export const createUserPackages = async (payload) => {
  const { user_id, packages, duration, mode_id } = payload
  let promiseArray
  let res
  if (packages?.length) {
    promiseArray = packages.map((packageId) => {
      createUserPackage({
        user_id: user_id,
        package_id: packageId,
        duration: duration,
        mode_id: mode_id,
      })
    })

    res = await Promise.allSettled(promiseArray)
  }
  return res
}
export const useCreateUserPackages = ({ queryOptions } = {}) => {
  return useMutation(createUserPackages, {
    ...queryOptions,
  })
}
// -----------------------------------
export const updatePackage = async ({ id, payload }) => {
  const { data } = await auth({
    method: 'put',
    url: `auth/pkg/packages/${id}`,
    data: {
      ...payload,
    },
  })
  return data
}
export const useUpdatePackage = ({ queryOptions } = {}) => {
  return useMutation(updatePackage, {
    ...queryOptions,
  })
}
// -----------------------------------
export const deletePackage = async (id) => {
  const data = await auth({
    method: 'delete',
    url: `auth/pkg/packages/${id}`,
  })
  return data
}
export const useDeletePackage = ({ queryOptions } = {}) => {
  return useMutation(deletePackage, {
    ...queryOptions,
  })
}
// -----------------------------------
export const deleteUserPackage = async (id) => {
  await auth({
    method: 'delete',
    url: `auth/pkg/user/packages/${id}`,
  })
}
export const useDeleteUserPackage = ({ queryOptions } = {}) => {
  return useMutation(deleteUserPackage, {
    ...queryOptions,
  })
}
// -----------------------------------
export const updateUserPackage = async ({ id, payload }) => {
  const { data } = await auth({
    method: 'put',
    url: `auth/pkg/user/packages/${id}`,
    data: {
      ...payload,
    },
  })
  return data
}
export const useUpdateUserPackage = ({ queryOptions } = {}) => {
  return useMutation(updateUserPackage, {
    ...queryOptions,
  })
}
// --------------------------------------
export const readPackage = async ({ queryKey }) => {
  const [, id] = queryKey
  const { data } = await auth({
    method: 'get',
    url: `auth/pkg/packages/${id}/read`,
  })
  return data
}
export const useReadPackage = (packageId, { queryOptions = {} } = {}) => {
  return useQuery(['packageURL', packageId], readPackage, {
    cacheTime: 1000 * 60 * 1000,
    staleTime: 1000 * 60 * 1000,
    ...queryOptions,
  })
}
//-------------------------------------------------
export const readPackageZiptales = async (id) => {
  const { data } = await auth({
    method: 'get',
    url: `auth/pkg/packages/${id}/read`,
  })
  return data
}

export const useReadPackageZiptales = ({ queryOptions } = {}) => {
  return useMutation(readPackageZiptales, {
    ...queryOptions,
  })
}
//-------------------------------------------------
export const launchPlatforms = async (id) => {
  const { data } = await auth({
    method: 'get',
    url: `auth/pkg/packages/${id}/launch`,
  })
  return data
}

export const useLaunchPlatforms = ({ queryOptions } = {}) => {
  return useMutation(launchPlatforms, {
    ...queryOptions,
  })
}
// --------------------------------------
export const readPackageNoCache = async ({ queryKey }) => {
  const [, id] = queryKey
  const { data } = await auth({
    method: 'get',
    url: `auth/pkg/packages/${id}/read?cache=false`,
  })
  return data
}
export const useReadPackageSkillAssignment = (
  packageId,
  { queryOptions = {} } = {}
) => {
  return useQuery(['packageURL', packageId], readPackageNoCache, {
    cacheTime: 1000 * 60 * 1000,
    staleTime: 1000 * 60 * 1000,
    ...queryOptions,
  })
}
// -----------------------------------
export const parseOPF = async ({ queryKey }) => {
  let parserXML = new xml2js.Parser()
  let parser = new DOMParser()
  // const [, url] = queryKey
  const [, url, version] = queryKey
  if (!url) return
  const { data } = await auth({
    method: 'get',
    // url: url + 'OPS/package.opf',
    url: url + 'OPS/package.opf' + (version ? `?v=${version}` : ''),
    withCredentials: true,
  })
  let toc = {}
  let activities = []

  const result = await parserXML.parseStringPromise(data)
  // let itemsManifest = result.package.manifest[0].item
  let xmlItems = result.package.spine[0].itemref
  let pages = []
  if (xmlItems?.length) {
    let nonLinear = xmlItems.filter((item) => item.$['linear'] === 'no')

    for (let itemref of nonLinear) {
      const found = result.package.manifest[0].item.find(
        (item) => item.$['id'] === itemref.$['idref']
      )
      if (found) pages.push(found.$)
    }
  }
  let nonLinearIds = pages.map((page) => page.id)

  let xmlDoc = parser.parseFromString(data, 'text/xml')
  let manifest = xmlDoc.getElementsByTagName('manifest')[0]
  let items = manifest.getElementsByTagName('item')

  let spine = xmlDoc.getElementsByTagName('spine')[0]
  let itemrefs = spine.getElementsByTagName('itemref')

  for (let i = 0; i < itemrefs.length; i++) {
    let idref = itemrefs[i].attributes.getNamedItem('idref').value
    let node = Array.from(items).filter(
      (itm) => itm.attributes.getNamedItem('id').value === idref
    )[0]
    let temp = {}
    for (let { name, value } of node.attributes) {
      temp[name] = value
    }
    if (!nonLinearIds.includes(idref)) {
      activities.push({ ...temp, url })
    }
    toc[idref] = { ...temp, url }
  }
  return [toc, activities, data, pages]
}
// export const useParseOPF = (url, { queryOptions } = {}) => {
export const useParseOPF = (url, version = 1, { queryOptions } = {}) => {
  // return useQuery(['packageParse', url], parseOPF, {
  return useQuery(['packageParse', url, version], parseOPF, {
    cacheTime: !url ? 0 : 1000 * 60 * 60,
    staleTime: !url ? 0 : 1000 * 60 * 60,
    ...queryOptions,
  })
}
// -----------------------------------
export const redeemPackageCode = async (payload) => {
  const { data } = await auth({
    method: 'post',
    url: `auth/pkg/codes/redeem`,
    data: {
      redeem_code: payload,
    },
  })
  return data
}
export const useRedeemPackageCode = ({ queryOptions } = {}) => {
  return useMutation(redeemPackageCode, {
    ...queryOptions,
  })
}
// -----------------------------------
export const validateCode = async ({ reCaptchaToken, redeemCode }) => {
  const { data } = await auth({
    method: 'post',
    url: `guest/pkg/codes/validate`,
    data: {
      'g-recaptcha-response': reCaptchaToken,
      validate: {
        redeem_code: redeemCode,
      },
    },
  })
  return data
}
export const useValidateCode = ({ queryOptions } = {}) => {
  return useMutation(validateCode, {
    ...queryOptions,
  })
}

// -----------------------------------
export const readAndParsePackage = async ({ queryKey }) => {
  const [, id, version] = queryKey
  // const [, id] = queryKey
  // if (includeModels?.includes('Package')) {
  //   const pkg = await getPackage({
  //     queryKey: [null, id],
  //   })
  //   dispatch(setPackage(pkg))
  // }
  const { url: signedUrl } = await readPackage({
    queryKey: [null, id],
  })

  // dispatch(setURL(signedUrl))

  const [toc, activities] = await parseOPF({
    queryKey: [null, signedUrl, version],
    // queryKey: [null, signedUrl],
  })
  // dispatch(setToc(activities))
  // dispatch(setSpine(toc))

  return { toc, activities, url: signedUrl }
}
export const useReadAndParsePackage = (
  packageId,
  version = 1,
  { queryOptions } = {}
) => {
  return useQuery(['packageRead', packageId, version], readAndParsePackage, {
    // export const useReadAndParsePackage = (packageId, { queryOptions } = {}) => {
    // return useQuery(['packageRead', packageId], readAndParsePackage, {
    cacheTime: 1000 * 60 * 1000,
    staleTime: 1000 * 60 * 1000,
    ...queryOptions,
  })
}
// -----------------------------------  READ
export const openPackage = async ({ Package, open }) => {
  dispatch(setPackage(Package))

  const { url: signedUrl } = await readPackage({
    queryKey: [null, Package.id],
  })

  dispatch(setURL(signedUrl))

  const [toc, activities] = await parseOPF({
    queryKey: [null, signedUrl],
  })

  dispatch(setToc(activities))
  dispatch(setSpine(toc))
  dispatch(setActivities([]))
  dispatch(setReadType('read'))
  if (open) {
    const win = window.open(
      `${window.location.origin}/reader/${Package.id}`,
      '_blank'
    )
    if (win != null) {
      win.focus()
    }
  }
}
export const useOpenPackage = ({ queryOptions } = {}) => {
  return useMutation(openPackage, {
    ...queryOptions,
  })
}
// -----------------------------------  ASSIGNMENT
export const openAssignment = async ({ Package, Activity, open }) => {
  dispatch(setPackage(Package))

  const { url: signedUrl } = await readPackage({
    queryKey: [null, Package.id],
  })

  dispatch(setURL(signedUrl))

  const [toc, activities] = await parseOPF({
    queryKey: [null, signedUrl],
  })

  dispatch(setToc(activities))
  dispatch(setSpine(toc))
  dispatch(setActivity(Activity.idref))
  dispatch(setActivities([Activity.idref]))
  dispatch(setReadType('assignment'))
  if (open) {
    const win = window.open(
      `${window.location.origin}/reader/${Package.id}/${Activity.idref}`,
      '_blank'
    )
    if (win != null) {
      win.focus()
    }
  }
}
export const useOpenAssignment = ({ queryOptions } = {}) => {
  return useMutation(openAssignment, {
    ...queryOptions,
  })
}

// -----------------------------------  SKILL ASSIGNMENT
export const openPackageSimple = async ({ Package }) => {
  dispatch(setPackage(Package))

  const { url: signedUrl } = await readPackage({
    queryKey: [null, Package.id],
  })

  dispatch(setURL(signedUrl))

  const [toc, activities] = await parseOPF({
    queryKey: [null, signedUrl],
  })

  dispatch(setToc(activities))
  dispatch(setSpine(toc))
  dispatch(setActivities([]))
  dispatch(setReadType('read'))
  // if (open) {
  //   const win = window.open(
  //     `${window.location.origin}/reader/${Package.id}`,
  //     '_blank'
  //   )
  //   if (win != null) {
  //     win.focus()
  //   }
  // }
  return [toc, activities]
}
export const useOpenPackageSimple = ({ queryOptions } = {}) => {
  return useMutation(openPackageSimple, {
    ...queryOptions,
  })
}

// -----------------------------------
export const getPackageFormats = async () => {
  const { data } = await auth({
    method: 'get',
    url: `auth/pkg/packages/formats?per_page=0`,
  })
  return data
}
export const usePackageFormats = ({ queryOptions } = {}) => {
  return useQuery(['packageFormats'], getPackageFormats, {
    cacheTime: Infinity,
    staleTime: Infinity,
    ...queryOptions,
  })
}

// -----------------------------------
export const getPackageCategories = async () => {
  const { data } = await auth({
    method: 'get',
    url: `auth/pkg/packages/categories?per_page=0`,
  })
  return data
}
export const usePackageCategories = ({ queryOptions } = {}) => {
  return useQuery(['packageCategories'], getPackageCategories, {
    cacheTime: Infinity,
    staleTime: Infinity,
    ...queryOptions,
  })
}
